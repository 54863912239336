<template>
  <div>
    <div v-if="type === 'bar'">
      <Bar
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
      />
    </div>
    <div v-if="type === 'line'">
      <LineChart
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
      />
    </div>
    <div v-if="type === 'pie'">
      <PieChart
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
      />
    </div>

  </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy';
import { Line as LineChart } from 'vue-chartjs/legacy';
import { Pie as PieChart } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement, ArcElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement, ArcElement)

export default {
  name: 'Charts',
  components: { Bar, LineChart, PieChart },
  props: {
    type: {
      type: String,
      default: 'bar'
    },
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 300
    },
    height: {
      type: Number,
      default: 150
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    },
    // plugins: {
    //   type: Array,
    //   default: () => []
    // },
    chartData: {
      type: Object,
      default: () => {}
    },
    chartOptions: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // Example data - when you pass data from parent component it should be provided in similar fashion as below
      // chartData: {
      //   labels: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ],
      //   datasets: [ {
      //     label: 'Bookings Per Month',
      //     data: [40, 20, 12],
      //     backgroundColor: "rgba(25, 181, 254, 0.7)",
      //     fill: false,
      //     borderColor: "rgba(25, 181, 254, 0.7)",
      //     // tension: 0.1,
      //     // pointRadius: 1,
      //     // borderWidth: 2,
      //   } ],
      // },
      // chartOptions: {
      //   responsive: true
      // },
    }
  }
}
</script>