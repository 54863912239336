<template>
  <div class="ps-5 pe-5 dashboard-statistics">
    <div class="row">
      <div class="col-12">
        <div class="">

          <div class="h-auto">

            <div class="row h-100 align-items-center pt-3 m-0">
              <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 m-0">
                <div class="card shadow sm">
                  <div class="card-body p-0">

                    <div v-if="loading" class="row m-0 p-0">
                      <!-- Loading -->
                      <Loader class="mt-2" :loading="loading" :small="false"></Loader>
                    </div>

                    <div v-if="!loading" class="row m-0 p-0">
                      <div class="row m-0 p-2 border-bottom">
                        <div class="col-12 col-xs-12 col-sm-9 col-md-9 col-lg-9">
                          <div v-if="this.$store.state.auth.user && !this.$store.state.auth.user.is_host">
                            <small>Welcome <span class="green-text">{{ this.$store.state.auth.user && this.$store.state.auth.user.contact ? this.$store.state.auth.user.contact.name : 'Anonymous'}}</span>! This is your Statistic Dashboard, on left side of your screen you will find Dashboard Menu.</small>
  <!--                          <hr class="m-2 p-0" style="color: #dee2e6; opacity: 0.7;"/>-->
  <!--                          <small>-->
  <!--                            In order to become a Host and have access to your Financial Dashboard (Stripe) you need to connect your account with Stripe.-->
  <!--                            By clicking on <strong><i>Become Host</i></strong> button you will start onboarding process.-->
  <!--                          </small>-->
                          </div>
                          <div v-if="this.$store.state.auth.user && this.$store.state.auth.user.is_host">
                            <small>Welcome <span class="green-text">{{ this.$store.state.auth.user && this.$store.state.auth.user.contact ? this.$store.state.auth.user.contact.name : 'Anonymous'}}</span>! This is your Statistic Dashboard, on left side of your screen you will find Dashboard Menu.</small>
                          </div>
                        </div>
                        <div class="col-12 col-xs-12 col-sm-3 col-md-3 col-lg-3">
                          <div>
                            <p><strong>Host Account:</strong> <span :class="isActiveHostAccount == 'Active' ? 'green-text' : 'red-text'">{{ isActiveHostAccount }}</span></p>
                          </div>
                          <!-- Connect Stripe Account -->
                          <div v-if="this.$store.state.auth.user && !this.$store.state.auth.user.is_host" class="col-12 mb-2 text-center">
                            <a class="text-decoration-none" :href="`${this.$apiUrl}` + '/api/user/stripe-onboarding'">
                              <button type="button" :disabled="processing" class="btn btn-outline-primary btn-block">
                                {{ processing ? "Please wait" : "Become Host" }}
                              </button>
                            </a>
                          </div>
                          <!-- Navigate to Stripe Dashboard -->
                          <div v-if="this.$store.state.auth.user && this.$store.state.auth.user.is_host" class="col-12 mb-2 text-center">
                            <a class="text-decoration-none" target="_blank" href="https://dashboard.stripe.com/login">
                              <button type="button" :disabled="processing" class="btn btn-outline-primary btn-block">
                                {{ processing ? "Please wait" : "Financial Dashboard" }}
                              </button>
                            </a>
  <!--                          <a class="text-decoration-none" target="_blank" :href="`${this.$apiUrl}` + '/api/user/pay-host?id=' + this.$store.state.auth.user.id">-->
  <!--                            <button type="button" :disabled="processing" class="btn btn-outline-primary btn-block">-->
  <!--                              {{ processing ? "Please wait" : "Pay Host" }}-->
  <!--                            </button>-->
  <!--                          </a>-->
                          </div>
                        </div>
                      </div>

                      <div class="row m-0 p-2">
                        <!-- Reservations / Short-Term Lets Per Month for the whole Year -->
                        <div v-if="this.$store.state.auth.user && this.$store.state.auth.user.is_host && this.$store.state.auth.user.done_onboarding" class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2 position-relative">
                          <div v-if="loadingReservations" class="loading-reservations-container">
                            <Loader :loading="loadingReservations" :small="true"></Loader>
                          </div>
                          <select
                              id="selectYearReservations"
                              class="options-select form-select select-placeholder cursor-pointer"
                              v-model="select_year_reservations"
                          >
                            <option value="" disabled>Year</option>
                            <option v-for="year in years" :value="year">{{ year }}</option>
                          </select>
                          <Charts
                              type="line"
                              :chart-options="chartReservationsOptions"
                              :chart-data="chartReservationsData"
                              :chart-id="chartReservationsData ? chartReservationsData.chartId : '1'"
                              :dataset-id-key="chartReservationsData ? chartReservationsData.datasetIdKey : '1'"
                              :width="chartReservationsData ? chartReservationsData.width : 300"
                              :height="chartReservationsData ? chartReservationsData.height : 150"
                          />
                        </div>

                        <!-- Payouts / For Reservations/Short-Term Lets Per Month for the whole Year -->
                        <div v-if="this.$store.state.auth.user && this.$store.state.auth.user.is_host && this.$store.state.auth.user.done_onboarding" class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2 position-relative">
                          <div v-if="loadingPayouts" class="loading-payouts-container">
                            <Loader :loading="loadingPayouts" :small="true"></Loader>
                          </div>
                          <select
                              id="selectYearPayouts"
                              class="options-select form-select select-placeholder cursor-pointer"
                              v-model="select_year_payouts"
                          >
                            <option value="" disabled>Year</option>
                            <option v-for="year in years" :value="year">{{ year }}</option>
                          </select>
                          <Charts
                              type="bar"
                              :chart-options="chartReservationPayoutsOptions"
                              :chart-data="chartReservationPayoutsData"
                              :chart-id="chartReservationPayoutsData ? chartReservationPayoutsData.chartId : '1'"
                              :dataset-id-key="chartReservationPayoutsData ? chartReservationPayoutsData.datasetIdKey : '1'"
                              :width="chartReservationPayoutsData ? chartReservationPayoutsData.width : 300"
                              :height="chartReservationPayoutsData ? chartReservationPayoutsData.height : 150"
                          />
                        </div>

                        <!-- Viewings Per Month for the whole Year -->
                        <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2 position-relative">
                          <div v-if="loadingViewings" class="loading-viewings-container">
                            <Loader :loading="loadingViewings" :small="true"></Loader>
                          </div>
                          <select
                              id="selectYearViewings"
                              class="options-select form-select select-placeholder cursor-pointer"
                              v-model="select_year_viewings"
                          >
                            <option value="" disabled>Year</option>
                            <option v-for="year in years" :value="year">{{ year }}</option>
                          </select>
                          <Charts
                              type="line"
                              :chart-options="chartViewingsOptions"
                              :chart-data="chartViewingsData"
                              :chart-id="chartViewingsData ? chartViewingsData.chartId : '1'"
                              :dataset-id-key="chartViewingsData ? chartViewingsData.datasetIdKey : '1'"
                              :width="chartViewingsData ? chartViewingsData.width : 300"
                              :height="chartViewingsData ? chartViewingsData.height : 150"
                          />
                        </div>

                        <!-- Appointments / Services Per Month for the whole Year -->
                        <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2 position-relative">
                          <div v-if="loadingAppointments" class="loading-appointments-container">
                            <Loader :loading="loadingAppointments" :small="true"></Loader>
                          </div>
                          <select
                              id="selectYearAppointments"
                              class="options-select form-select select-placeholder cursor-pointer"
                              v-model="select_year_appointments"
                          >
                            <option value="" disabled>Year</option>
                            <option v-for="year in years" :value="year">{{ year }}</option>
                          </select>
                          <Charts v-if="chartAppointmentsData && Object.keys(chartAppointmentsData).length > 0"
                              type="line"
                              :chart-options="chartAppointmentsOptions"
                              :chart-data="chartAppointmentsData"
                              :chart-id="chartAppointmentsData ? chartAppointmentsData.chartId : '1'"
                              :dataset-id-key="chartAppointmentsData ? chartAppointmentsData.datasetIdKey : '1'"
                              :width="chartAppointmentsData ? chartAppointmentsData.width : 300"
                              :height="chartAppointmentsData ? chartAppointmentsData.height : 150"
                          />
                        </div>

  <!--                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">-->
  <!--                        <div><font-awesome-icon icon="wrench" style="color: blue; font-size: 32px;" /> <font-awesome-icon icon="cogs" style="color: slategrey; font-size: 32px;" /></div>-->
  <!--                        <p class="mb-0">UNDER CONSTRUCTION</p>-->
  <!--                        <p class="mb-0">Feel free to use navigation on the left side of the screen</p>-->
  <!--                      </div>-->
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import Charts from "../Charts";
import Loader from "../Loader";

export default {
  name: "DashboardStatistics",
  components: {
    'Charts': Charts,
    'Loader': Loader
  },
  props: ['is_boarded'],
  data(){
    return {
      user: this.$store.state.auth.user,
      processing: false,
      loading: true,

      loadingReservations: false,
      loadingPayouts: false,
      loadingViewings: false,
      loadingAppointments: false,

      select_year_reservations: this.moment().format('Y'),
      select_year_payouts: this.moment().format('Y'),
      select_year_viewings: this.moment().format('Y'),
      select_year_appointments: this.moment().format('Y'),

      /* Charts Data */
      // From Api Calls
      reservationsData: '',
      reservationPayoutsData: '',
      viewingsData: '',
      appointmentsData: '',

      // Reservations per Month
      chartReservationsData: {},
      chartReservationsOptions: {
        responsive: true,
        ticks: {
          stepSize: 1
        },
        plugins: {
          title: {
            text: 'Reservations',
            position: 'top',
            display: true,
            align: 'center',
            color: 'black',
            font: {
              size: 15
            }
          }
        },
      },

      // Payouts / Reservations per Month
      chartReservationPayoutsData: {},
      chartReservationPayoutsOptions: {
        responsive: true,
        ticks: {
          stepSize: 1
        },
        scales: {
          y: {
            ticks: {
              // Include a pound sign in the ticks
              callback: function(value, index, ticks) {
                return '£' + value;
              }
            }
          }
        },
        plugins: {
          title: {
            text: 'Payouts',
            position: 'top',
            display: true,
            align: 'center',
            color: 'black',
            font: {
              size: 15
            }
          },
          legend: {
            display: true,
            // We overwrite click so that it does not trigger anything
            onClick: () => null,
            // We set labels as empty with transparent colors and width-height of 10 so that we can create
            // illusion of 'margin' or 'padding' between title and chart data
            labels: {
              boxWidth: 10,
              boxHeight: 10,
              generateLabels: (chart) => {
                const datasets = chart.data.datasets;
                return datasets[0].data.map((data, i) => ({
                  text: '',
                  fillStyle: 'rgba(255, 255, 255, 0)',
                  strokeStyle: 'rgba(255, 255, 255, 0)',
                  borderStyle: 'rgba(255, 255, 255, 0)',
                }))
              }
            }
          }
        },
      },

      // Viewings per Month
      chartViewingsData: {},
      chartViewingsOptions: {
        responsive: true,
        ticks: {
          stepSize: 1
        },
        plugins: {
          title: {
            text: 'Viewings',
            position: 'top',
            display: true,
            align: 'center',
            color: 'black',
            font: {
              size: 15
            }
          }
        },
      },

      // Appointments per Month
      chartAppointmentsData: {},
      chartAppointmentsOptions: {
        responsive: true,
        ticks: {
          stepSize: 1
        },
        plugins: {
          title: {
            text: 'Appointments',
            position: 'top',
            display: true,
            align: 'center',
            color: 'black',
            font: {
              size: 15
            }
          }
        },
      },

    }
  },
  async mounted() {
    this.processing = true;
    this.loading = true;

    if (this.is_boarded) {
      await axios.post('api/stripe/check-user-onboarding').then(({data})=>{
        // console.log(data);
        this.validationErrors = null;
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
        this.processing = false;
        this.loading = false;
      }).finally(()=>{
      })
    }

    this.processing = true;
    // Get statistics for charts (vue-charts.js/legacy)
    if (this.$store.state.auth.user && this.$store.state.auth.user.is_host && this.$store.state.auth.user.done_onboarding) {
      await this.getReservationsStatistics(this.moment().format('YYYY'));
      await this.getReservationPayoutsStatistics(this.moment().format('YYYY'));
    }
    await this.getViewingsStatistics(this.moment().format('YYYY'));
    await this.getAppointmentsStatistics(this.moment().format('YYYY'));

    // We want to refresh user every time we enter statistics, especially that is_host might change on user based on
    // creation of Stripe Account
    await this.refreshUser();
    this.processing = false;
    this.loading = false;
  },
  computed: {
    isActiveHostAccount() {
      if (this.$store.state.auth.user && this.$store.state.auth.user.is_host && this.$store.state.auth.user.done_onboarding) {
        return 'Active';
      } else {
        return 'Not Activated';
      }
    },
    years() {
      let max = new Date().getFullYear();
      let min = 2021;
      let years = []

      for (let i = max; i >= min; i--) {
        years.push(i)
      }
      return years
    },
  },
  methods: {
    ...mapActions({
      refreshUser:"auth/refreshUser"
    }),
    async getReservationsStatistics(year) {
      let formData = new FormData();

      formData.append('user_id', this.$store.state.auth.user.id);
      formData.append('year', year);

      await axios.post('api/user/reservations-statistics', formData).then(({data})=>{
        this.reservationsData = data;
        this.validationErrors = null;

        // Assign chart reservations data
        this.chartReservationsData = {
          labels: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ],
          datasets: [
            {
              label: 'All',
              data: this.reservationsData.allBookingsCount,
              backgroundColor: ['rgba(25, 181, 254, 1)'],
              color: ['rgba(25, 181, 254, 1)'],
              borderColor: 'rgba(25, 181, 254, 1)',
            },
            {
              label: 'Completed',
              data: this.reservationsData.completedBookingsCount,
              backgroundColor: ['limegreen'],
              color: ['limegreen'],
              borderColor: 'limegreen',
            },
            {
              label: 'Cancelled',
              data: this.reservationsData.cancelledBookingsCount,
              backgroundColor: ['red'],
              color: ['red'],
              borderColor: 'red',
            },
            {
              label: 'Declined',
              data: this.reservationsData.declinedBookingsCount,
              backgroundColor: ['orange'],
              color: ['orange'],
              borderColor: 'orange',
            }
          ],
          width: 300,
          height: 150,
          chartId: 'reservationsChart',
          datasetIdKey: 'reservationsChartDataset'
        };
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
        this.processing = false;
        this.loading = false;
      }).finally(()=>{
      })
    },
    async getReservationPayoutsStatistics(year) {
      let formData = new FormData();

      formData.append('user_id', this.$store.state.auth.user.id);
      formData.append('year', year);

      await axios.post('api/user/payouts-statistics', formData).then(({data})=>{
        this.reservationPayoutsData = data;
        this.validationErrors = null;

        // Assign chart reservations data
        this.chartReservationPayoutsData = {
          labels: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ],
          datasets: [
            {
              data: this.reservationPayoutsData.payoutsAmountCount,
              backgroundColor: ['rgba(25, 181, 254, 0.7)', 'rgba(204,0,204,0.7)'],
              color: ['rgba(25, 181, 254, 0.7)', 'rgba(204,0,204,0.7)'],
              borderColor: ['rgba(25, 181, 254, 0.7)', 'rgba(204,0,204,0.7)'],
            },
          ],
          width: 300,
          height: 150,
          chartId: 'reservationPayoutsChart',
          datasetIdKey: 'reservationPayoutsChartDataset'
        };
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
        this.processing = false;
        this.loading = false;
      }).finally(()=>{
      })
    },
    async getViewingsStatistics(year) {
      let formData = new FormData();

      formData.append('user_id', this.$store.state.auth.user.id);
      formData.append('year', year);

      await axios.post('api/user/viewings-statistics', formData).then(({data})=>{
        this.viewingsData = data;
        this.validationErrors = null;

        // Assign chart appointments data
        this.chartViewingsData = {
          labels: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ],
          datasets: [
            {
              label: 'All',
              data: this.viewingsData.allBookingsCount,
              backgroundColor: ['rgba(25, 181, 254, 1)'],
              color: ['rgba(25, 181, 254, 1)'],
              borderColor: 'rgba(25, 181, 254, 1)',
            },
            {
              label: 'Completed',
              data: this.viewingsData.completedBookingsCount,
              backgroundColor: ['limegreen'],
              color: ['limegreen'],
              borderColor: 'limegreen',
            },
            {
              label: 'Cancelled',
              data: this.viewingsData.cancelledBookingsCount,
              backgroundColor: ['red'],
              color: ['red'],
              borderColor: 'red',
            },
            {
              label: 'Declined',
              data: this.viewingsData.declinedBookingsCount,
              backgroundColor: ['orange'],
              color: ['orange'],
              borderColor: 'orange',
            }
          ],
          width: 300,
          height: 150,
          chartId: 'viewingsChart',
          datasetIdKey: 'viewingsChartDataset'
        };
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
        this.processing = false;
        this.loading = false;
      }).finally(()=>{
      })
    },
    async getAppointmentsStatistics(year) {
      let formData = new FormData();

      formData.append('user_id', this.$store.state.auth.user.id);
      formData.append('year', year);

      await axios.post('api/user/appointments-statistics', formData).then(({data})=>{
        this.appointmentsData = data;
        this.validationErrors = null;

        // Assign chart appointments data
        this.chartAppointmentsData = {
          labels: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ],
          datasets: [
            {
              label: 'All',
              data: this.appointmentsData.allBookingsCount,
              backgroundColor: ['rgba(25, 181, 254, 1)'],
              color: ['rgba(25, 181, 254, 1)'],
              borderColor: 'rgba(25, 181, 254, 1)',
            },
            {
              label: 'Completed',
              data: this.appointmentsData.completedBookingsCount,
              backgroundColor: ['limegreen'],
              color: ['limegreen'],
              borderColor: 'limegreen',
            },
            {
              label: 'Cancelled',
              data: this.appointmentsData.cancelledBookingsCount,
              backgroundColor: ['red'],
              color: ['red'],
              borderColor: 'red',
            },
            {
              label: 'Declined',
              data: this.appointmentsData.declinedBookingsCount,
              backgroundColor: ['orange'],
              color: ['orange'],
              borderColor: 'orange',
            }
          ],
          width: 300,
          height: 150,
          chartId: 'appointmentsChart',
          datasetIdKey: 'appointmentsChartDataset'
        };
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
        this.processing = false;
        this.loading = false;
      }).finally(()=>{
      })
    }
  },
  watch: {
    "select_year_reservations": {
      handler: async function (year) {
        if (year !== null) {
          this.loadingReservations = true;
          await this.getReservationsStatistics(year);
          this.loadingReservations = false;
        }
      }, deep: true
    },
    "select_year_payouts": {
      handler: async function (year) {
        if (year !== null) {
          this.loadingPayouts = true;
          await this.getReservationPayoutsStatistics(year);
          this.loadingPayouts = false;
        }
      }, deep: true
    },
    "select_year_viewings": {
      handler: async function (year) {
        if (year !== null) {
          this.loadingViewings = true;
          await this.getViewingsStatistics(year);
          this.loadingViewings = false;
        }
      }, deep: true
    },
    "select_year_appointments": {
      handler: async function (year) {
        if (year !== null) {
          this.loadingAppointments = true;
          await this.getAppointmentsStatistics(year);
          this.loadingAppointments = false;
        }
      }, deep: true
    },
  }
}
</script>

<style scoped lang="scss">

#selectYearReservations {
  position: absolute;
  width: 100px;
  right: 10px;
  top: 10px;
}
#selectYearPayouts {
  position: absolute;
  width: 100px;
  right: 10px;
  top: 10px;
}
#selectYearViewings {
  position: absolute;
  width: 100px;
  right: 10px;
  top: 10px;
}
#selectYearAppointments {
  position: absolute;
  width: 100px;
  right: 10px;
  top: 10px;
}

.loading-reservations-container {
  .loader {
    top: 50% !important;
  }
}
.loading-payouts-container {
  .loader {
    top: 50% !important;
  }
}
.loading-viewings-container {
  .loader {
    top: 50% !important;
  }
}
.loading-appointments-container {
  .loader {
    top: 50% !important;
  }
}

.dashboard-statistics {
  min-height: 600px;
}

.dashboard-header {
  letter-spacing: 2px;
}

</style>